<template>
	<div class="animated fadeIn">
		<b-row>
			<div class="p-3">
				<span class="font-weight-bold">{{ translate('summary') }}</span>
			</div>
			<b-col class="col-12">
				<b-tabs v-model="tabIndex">
					<b-tab
						v-for="(tabInfo, index) in tabs"
						:key="index">
						<template slot="title">
							<router-link
								v-if="!tabInfo.has_permission || $can(tabInfo.permission_section, tabInfo.permission_action)"
								:class="$route.name === tabInfo.name ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
								:to="{ name: tabInfo.name, query }"
								class="list-group-item btn text-left">
								{{ translate(tabInfo.translate_key) }}
							</router-link>
						</template>
						<template v-if="tabIndex === index">
							<router-view />
						</template>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { Settings as messages } from '@/translations';

export default {
	name: 'Settings',
	messages,
	data() {
		return {
			tabIndex: 0,
			tabs: [
				{
					name: 'SettingsGeneral',
					has_permission: false,
					permission_section: '',
					permission_action: '',
					translate_key: 'general',
				},
				{
					name: 'ExchangeRates',
					has_permission: true,
					permission_section: 'exchange_rates',
					permission_action: 'view',
					translate_key: 'exchange_rates',
				},
			],
		};
	},
	computed: {
		query() {
			return this.$route.query;
		},
	},
	mounted() {
		if (typeof this.$route.name !== 'undefined') {
			this.tabIndex = this.tabs.findIndex((tabInfo) => tabInfo.name === this.$route.name);
		}
	},
};
</script>
